import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About me`}</h2>
    <p>{`webサービスを作るエンジニア。都内のIT企業に勤めています。`}<br />{`
バックエンドを主戦場としており、個人開発のためにデザインなどバックエンド以外の分野も勉強中・・・`}<br /></p>
    <br />
    <h4>{`Specialties`}</h4>
    <ul>
      <li parentName="ul">{`Golang`}</li>
      <li parentName="ul">{`Java`}</li>
      <li parentName="ul">{`AWS`}</li>
      <li parentName="ul">{`Infrastructure as Code (IaC)`}</li>
      <li parentName="ul">{`CI/CD`}</li>
      <li parentName="ul">{`Docker`}</li>
      <li parentName="ul">{`データレイク`}</li>
      <li parentName="ul">{`Slackベースの英語でのコミュニケーション`}</li>
    </ul>
    <br />
    <h4>{`History`}</h4>
    <table style={{
      "width": "auto"
    }}>
  <tr>
    <th style={{
          "textAlign": "center"
        }} width="15%">いつ</th>
    <th style={{
          "textAlign": "center"
        }} width="85%">何をしてた？</th>
  </tr>
  <tr>
    <td>2013年 4月</td>
    <td>大学入学（情報系学部）</td>
  </tr>
  <tr>
    <td>2017年 3月</td>
    <td>大学卒業</td>
  </tr>
  <tr>
    <td>2017年 4月</td>
    <td>メーカーの開発系子会社に入社。Windowsアプリケーション, Webアプリケーション開発に携わる</td>
  </tr>
  <tr>
    <td>2019年 5月</td>
    <td>プロジェクトの実績が評価され表彰を受ける</td>
  </tr>
  <tr>
    <td>2020年 1月</td>
    <td>都内のWeb系ソフトウェア開発企業に転職。toCのWeb・スマートフォン向けアプリケーション開発プロジェクトにアサイン</td>
  </tr>
  <tr>
    <td>2021年 10月</td>
    <td>同プロジェクトにおける認証機能の OAuth2.0 から OpenIDConnect 移行の開発を担当する</td>
  </tr>
  <tr>
    <td>2021年 10月</td>
    <td>同プロジェクトのインフラ担当を任される</td>
  </tr>
  <tr>
    <td>2022年 11月</td>
    <td>都内のWeb系事業会社へ転職。データエンジニアとしてプロジェクトにアサイン</td>
  </tr>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      