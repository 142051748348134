import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Contact`}</h2>
    <p>{`メールはこちらまで。`}<br />{`
`}<a parentName="p" {...{
        "href": "mailto:hnhhnnnz@gmail.com"
      }}>{`hnhhnnnz@gmail.com`}</a></p>
    <a href="https://github.com/UniTofu">GitHub</a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      